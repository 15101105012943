var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "supplychainAnalysis nav_tab_wrap" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "nav_wrap",
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "库销比", name: "librarypin" } },
            [
              _vm.islibrarypin
                ? _c("librarypinthan", { on: { activeObj: _vm.getActiveData } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "断货率", name: "rateofout" } },
            [
              _vm.israteofout
                ? _c("rateofoutof", {
                    attrs: { stock: "", subName: _vm.subName }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "滞销库存", name: "unsalable" } },
            [
              _vm.isunsalable
                ? _c("unsalable", { attrs: { stock: "" } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }